import config from '../config';
import LogRocket from 'logrocket';
import { User } from '@sep/cms-auth-client/cjs';

let isLogRocketInitialized = false;

export function initialize(user?: null | User) {
	if (!isLogRocketInitialized) {
		isLogRocketInitialized = true;

		LogRocket.init(config.LOGROCKET_PROJECT, {
			network: {
				requestSanitizer: (request) => {
					try {
						//
					} catch (e) {
						console.error(e);
					}

					return request;
				},
			},
		});
	}

	if (isLogRocketInitialized && user) {
		LogRocket.identify(user.email, {
			u: user.guid,
			email: user.email,
			name: [user.firstName, user.lastName].join(' '),
		});
	}
}
