import { describe, Type } from 'envconfig';

// type EnvConfigType = Parameters<typeof describe>[0];

// uncomment /*: EnvConfigType */ to get intellisense for config object when changing it
const config /*: EnvConfigType */ = {
	FIREBASE_AUTH_EMULATOR_HOST: {
		isOptional: true,
	},
	FIREBASE_DATABASE_EMULATOR_HOST: {
		isOptional: true,
	},
	AUTH_PROVIDER: {},
	NODE_ENV: {
		// we need to set this explicitly as it would otherwise be prepended with REACT_APP_
		name: 'NODE_ENV',
	},
	DEFAULT_LANGUAGE: {
		default: 'de_DE',
	},
	AUTH_CLIENT_ID: { default: 'cb72e4e5-bca1-474c-9e5f-6f0bb28f9fa8' },
	CMS_CLIENT_URL_EXT: { default: 'http://localhost:4040' },
	BRANCH: null,
	LOGROCKET_PROJECT: null,
	FIREBASE_CRED_API_KEY: null,
	FIREBASE_CRED_AUTH_DOMAIN: null,
	FIREBASE_CRED_DATABASE_URL: null,
	FIREBASE_CRED_PROJECT_ID: null,
	GRAPHQL_URL_EXT: null,
	RENDERER_EMBEDS_URL_EXT: null,
	SOPHORA_URL_EXT: null,
	WEB_CLIENT_URL_EXT: null,
	WEB_PREVIEW_CLIENT_URL_EXT: null,
	IMAGE_UPLOAD_URL_EXT: null,
	GA_TRACKING_CODE: {
		name: 'GA_TRACKING_CODE',
		default: '',
	},
	ENABLE_STORY_MERGE_FEATURE: {
		name: 'ENABLE_STORY_MERGE_FEATURE',
		isOptional: true,
		default: false,
	},
	FIREBASE_AUTH_URL_EXT: {
		default: '/firebase/token',
	},
	BRBILD_URL_EXT: null,
	BRBILD_USER: null,
	BRBILD_PASSWORD: null,
	ENABLE_IMPORTER_SOPHORA: {
		isOptional: true,
		default: false,
		type: 'boolean',
		sanitize: (value) => value === true || value === 'true',
	},
	REACT_APP_ALLOWED_BOARD_EMBEDS: {
		name: 'REACT_APP_ALLOWED_BOARD_EMBEDS',
		type: Type.ARRAY,
		sanitize: (value: string) => {
			return String(value)
				.split(',')
				.map((value) => value.trim());
		},
	},
};

/**
 * this is a little helper to prepend all configuration items that do not already have a `name` property with `REACT_APP_`
 */
for (const key in config) {
	type ConfigKey = keyof typeof config;

	const configItem = config[key as ConfigKey] as { [key: string]: any };

	const environmentVariableName = `REACT_APP_${key}`;

	if (configItem && typeof configItem === 'object' && !('name' in configItem)) {
		configItem.name = environmentVariableName;
	}

	if (configItem === null) {
		config[key as ConfigKey] = { name: environmentVariableName } as never;
	}
}

const environmentConfig = describe<typeof config>(
	config as typeof config,
	(window as any).config || process.env
);

export default environmentConfig;
