// @flow

// this file contains an abstract analytics toolset (currently based on google-analytics)

import * as React from 'react';
import {
	event as sendGAEvent,
	timing as sendGATiming,
	initialize as initializeGA,
	OutboundLink as GAOutboundLink,
	set,
	pageview,
} from 'react-ga';
import getDisplayName from 'react-display-name';
import type { ContextRouter } from 'react-router-dom';

import config from '../config';

function log(...args) {
	if (!config.NODE_ENV === 'development') {
		return;
	}

	console.log(...args); // eslint-disable-line no-console
}

export function initialize(): void {
	initializeGA(config.GA_TRACKING_CODE, {
		debug: config.NODE_ENV === 'development',
	});
}

type AnalyticsEvent = {
	category: string,
	action: string,
	label?: string,
	value?: number,
	nonInteraction?: boolean,
};

/**
 * Captures an analytics-event.
 *
 * - category: A top level category for these events. e.g. 'Header', 'Footer', 'Article', ...
 * - action: A description of the behaviour. e.g. 'Clicked Delete', 'Added a component', 'Deleted account', ...
 * - label: More precise labelling of the related action. e.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
 * - value: A means of recording a numerical value against an event. e.g. a rating, a score, ...
 * - nonInteraction: If an event is not triggered by a user interaction, but instead by our code (e.g. on page load, it should be flagged as a nonInteraction event to avoid skewing bounce rate data.
 *
 * @param {Object} event
 * @return {void}
 */
export function captureEvent(event: AnalyticsEvent): void {
	log('captureEvent', event);
	sendGAEvent(event);
}

type AnalyticsTiming = {
	category: string,
	variable: string,
	value?: number,
	label?: string,
};

/**
 * Allow to measure periods of time such as AJAX requests and resources loading.
 *
 * - category: A string for categorizing all user timing variables into logical groups.
 * - variable: Name of the variable being recorded.
 * - value: Number of milliseconds elapsed time to report.
 * - label: It can improved visibility in user timing reports.
 *
 * @param {Object} timing
 * @return {void}
 */
export function captureTiming(timing: AnalyticsTiming): void {
	log('captureTiming', timing);
	sendGATiming(timing);
}

type OutboundLinkProps = {
	to: string,
	target?: '_self' | '_blank',
	eventLabel: string,
	children: any,
};

export const OutboundLink = ({ children, ...rest }: OutboundLinkProps) => (
	<GAOutboundLink {...rest}>{children}</GAOutboundLink>
);

type WrappedComponentWithTrackerProps = ContextRouter & {};
type WithTrackerOptions = { [key: string]: any };

/**
 * @param {React.Component} WrappedComponent
 * @param {Object} options
 * @return {React.Component}
 */
export function withTracker(
	WrappedComponent: React.ComponentType<any>,
	options?: WithTrackerOptions = {}
): React.ComponentType<any> {
	const trackPage = page => {
		log(`trackPage ${page}`);

		set({
			page,
			...options,
		});
		pageview(page);
	};

	const WrappedComponentWithTracker = class extends React.Component<WrappedComponentWithTrackerProps> {
		displayName = `withTracker(${String(WrappedComponent.displayName)})`;

		componentDidMount() {
			const page = this.props.location.pathname;
			trackPage(page);
		}

		componentDidUpdate(prevProps: WrappedComponentWithTrackerProps) {
			const prevPage = prevProps.location.pathname;
			const currentPage = this.props.location.pathname;

			if (prevPage !== currentPage) {
				trackPage(currentPage);
			}
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	WrappedComponentWithTracker.displayName = `withTracker(${getDisplayName(WrappedComponent)})`;

	return WrappedComponentWithTracker;
}
