// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { App } from '@sep/cms-auth-client/cjs';
import config from './config';

import { render } from 'react-dom';
import { initialize as initializeLogRocket } from './util/logrocket';
import { initialize as initializeAnalytics } from './util/analytics';

let authContainer = document.getElementById('auth-root');

if (!authContainer) {
	authContainer = document.createElement('div');
	authContainer.id = 'auth-root';
	document.body.appendChild(authContainer);
}

const appRoot = document.getElementById('root');

if (!appRoot) {
	throw new Error('Root container not found');
}

const interceptUrls = [
	config.GRAPHQL_URL_EXT,
	config.SOPHORA_URL_EXT,
	`${config.IMAGE_UPLOAD_URL_EXT}/image-upload`,
	`${config.CMS_CLIENT_URL_EXT}${config.FIREBASE_AUTH_URL_EXT}`,
];

if (!['master', 'main'].includes(config.BRANCH)) {
	initializeLogRocket();
}

initializeAnalytics();

render(
	<App
		/* disable silent login for dex authentication */
		disableSilentLogin={true}
		appRootDomNode={appRoot}
		auth={{
			authEndpoint: '/auth',
			client_id: config.AUTH_CLIENT_ID,
			interceptUrls,
		}}
		serviceWorkerPath="/service-worker.js"
		render={async ({ user, logout }) => {
			if (!['master', 'main'].includes(config.BRANCH)) {
				initializeLogRocket(user);
			}

			if (!user) {
				return null;
			}

			const { default: BR24App } = await import('./app');
			return <BR24App user={user} logout={logout} />;
		}}
	/>,
	authContainer
);
